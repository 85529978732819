var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"member-row"},[_c('div',{class:[
            'member-row__column member-row__column--name',
            {'member-row__column--juristic' :_vm.isJuristic}
        ]},[_vm._v(" "+_vm._s(_vm.item.name)+" ")]),(!_vm.isJuristic)?_c('div',{staticClass:"member-row__column member-row__column--position"},[_vm._v(" "+_vm._s(!_vm.isJuristic ? _vm.item.position || '-' : '')+" ")]):_vm._e(),(!_vm.isJuristic)?_c('div',{staticClass:"member-row__column member-row__column--company"},[_vm._v(" "+_vm._s(!_vm.isJuristic ? _vm.companyTitle || '-' : '')+" ")]):_vm._e(),_c('div',{class:[
            'member-row__column member-row__column--description',
            {'member-row__column--juristic' :_vm.isJuristic}
        ]},[_vm._v(" "+_vm._s(_vm.item.role && _vm.item.role.name ? _vm.item.role.name : '-')+" ")]),(!_vm.isJuristic)?_c('div',{staticClass:"member-row__column member-row__column--status"},[_vm._v(" "+_vm._s(_vm.memberStatusText)+" ")]):_vm._e(),_c('div',{class:[
            'member-row__column member-row__column--date',
            {'member-row__column--juristic' :_vm.isJuristic}
        ]},[_vm._v(" "+_vm._s(_vm.firstDate)+" ")]),_c('div',{class:[
            'member-row__column member-row__column--date',
            {'member-row__column--juristic' :_vm.isJuristic}
        ]},[_vm._v(" "+_vm._s(_vm.secondDate)+" ")]),(!_vm.userRightOnlyGSN)?_c('div',{class:[
            'member-row__buttons',
            {'member-row__buttons--juristic' :_vm.isJuristic}
        ]},[_c('button',{staticClass:"member-row__button btn btn--outline-light",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('edit')}}},[_vm._v(" Изменить ")]),(!_vm.isJuristic)?_c('button',{staticClass:"member-row__button btn btn--outline-light member-row__button--activation",attrs:{"type":"button"},on:{"click":_vm.activationToggle}},[_vm._v(" "+_vm._s(_vm.statusText)+" ")]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }