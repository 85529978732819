














































































import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import { formatDate } from '@utility/formatDate';
import { namespace } from 'vuex-class';

const NSUser = namespace('storeUser');

import '../scss/MemberRow.scss';


@Component({
    name: 'MemberRow',
})

export default class MemberRow extends Vue {
    @Prop({}) item: any;
    @Prop({}) juristicMembers!: any[];
    @Prop({ default: false }) isJuristic!: boolean;
    @Prop({ default: false }) oppositeStatusRule!: boolean;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;

    get companyTitle() {
        if (this.juristicMembers && this.juristicMembers.length) {
            const company = this.juristicMembers.filter(item => item.id === this.item.juristicPersonId);
            if (company.length) {
                return company[0].name;
            }
        }
        return null;
    }

    get statusText() {
        if (this.oppositeStatusRule) {
            return !this.memberStatus() ? 'Сделать неактивным' : 'Активировать';
        }
        return this.memberStatus() ? 'Сделать неактивным' : 'Активировать';
    }

    get firstDate() {
        if (this.item) {
            if (this.isJuristic && this.item.startDate) {
                return formatDate({ date: new Date(this.item.startDate), format: 'dd.mm.yyyy' });
            } else if (this.item.activationDate) {
                return formatDate({ date: new Date(this.item.activationDate), format: 'dd.mm.yyyy' });
            }
        }
        return '-';
    }

    get secondDate() {
        if (this.item) {
            if (this.isJuristic && this.item.endDate) {
                return formatDate({ date: new Date(this.item.endDate), format: 'dd.mm.yyyy' });
            } else if (this.item.deactivationDate) {
                return formatDate({ date: new Date(this.item.deactivationDate), format: 'dd.mm.yyyy' });
            }
        }
        return '-';
    }

    get memberStatusText() {
        if (this.oppositeStatusRule) {
            return !this.memberStatus() ? 'Активен' : 'Неактивен';
        }
        return this.memberStatus() ? 'Активен' : 'Неактивен';
    }

    memberStatus() {
        if (this.item.deactivationDate || !this.item.activationDate) {
            return false;
        }
        return !this.item.deactivationDate && this.item.activationDate;
    }

    activationToggle() {
        this.$emit('activate-toggle', !this.memberStatus());
    }
}
